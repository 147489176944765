import {
  Icon,
  IconAddressBook,
  IconArrowBigDown,
  IconArrowBigLeft,
  IconArrowBigRight,
  IconArrowBigUp,
  IconArticle,
  IconBell,
  IconBellRinging,
  IconBook,
  IconBox,
  IconBrain,
  IconBriefcase,
  IconBrowser,
  IconCalendar,
  IconChalkboard,
  IconCrown,
  IconCurrencyDollar,
  IconEdit,
  IconForms,
  IconHammer,
  IconHeadset,
  IconHome,
  IconList,
  IconMail,
  IconMailbox,
  IconMessage,
  IconNavigation,
  IconPencil,
  IconProps,
  IconQuestionMark,
  IconSchool,
  IconSettings,
  IconSignRight,
  IconSpy,
  IconTargetArrow,
  IconTemplate,
  IconTool,
  IconTrendingUp,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";

export type TIcon = React.ForwardRefExoticComponent<
  Omit<IconProps, "ref"> & React.RefAttributes<Icon>
>;

export const iconMap = new Map<string, TIcon>([
  ["", IconQuestionMark],
  ["address-book", IconAddressBook],
  ["article", IconArticle],
  ["arrow-big-left", IconArrowBigLeft],
  ["arrow-big-right", IconArrowBigRight],
  ["arrow-big-up", IconArrowBigUp],
  ["arrow-big-down", IconArrowBigDown],
  ["book", IconBook],
  ["browser", IconBrowser],
  ["brain", IconBrain],
  ["box", IconBox],
  ["briefcase", IconBriefcase],
  ["calendar", IconCalendar],
  ["chalkboard", IconChalkboard],
  ["edit", IconEdit],
  ["forms", IconForms],
  ["home", IconHome],
  ["mail", IconMail],
  ["navigation", IconNavigation],
  ["list", IconList],
  ["pencil", IconPencil],
  ["spy", IconSpy],
  ["template", IconTemplate],
  ["user", IconUser],
  ["users", IconUsers],
  ["dollar", IconCurrencyDollar],
  ["target-arrow", IconTargetArrow],
  ["trending-up", IconTrendingUp],
  ["school", IconSchool],
  ["bell", IconBell],
  ["bell-ringing", IconBellRinging],
  ["headset", IconHeadset],
  ["crown", IconCrown],
  ["sign-right", IconSignRight],
  ["settings", IconSettings],
  ["message", IconMessage],
  ["mailbox", IconMailbox],
  ["tool", IconTool],
  ["hammer", IconHammer],
]);
